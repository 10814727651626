<template>

  <!-- {{user}} -->
  <div class="col-lg-7 col-md-7">
    <!-- 推薦不顯示新增貼文 -->
    <div class="col-sm-12">
      <tab-content id="pills-tabContent-2">
        <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
          <AddSocialPost @addPost="addPost" :settions="settions"></AddSocialPost>
        </tab-content-item>
      </tab-content>
    </div>
    <tab-content id="pills-tabContent-2" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult">
      <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
        <SocialPost :post="list" :settions="settions" :showSettions="false" />
      </tab-content-item>
    </tab-content>
  </div>

  <!-- 廣告區塊隱藏  -->
  <SocialRecommendedCreators></SocialRecommendedCreators>
  <!-- <div v-if="!commomData.noResult" class="col-sm-12 text-center">
    <img src="../../../assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
  </div> -->
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import SocialPost from './Components/SocialPost'
import AddSocialPost from './Components/AddSocialPost'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getPost } from '@/api/post'
import { getSettings } from '@/api/settings'
import { ref, onMounted } from 'vue'

export default {
  name: 'SocialFollow',
  components: { AddSocialPost, SocialPost },
  setup(props) {
    console.log(props)
    const settions = ref({})
    const getUserSettions = () => {
      getSettings()
        .then(res => {
          settions.value = res
        })
        .catch(err => {
          console.log(err)
        })
    }
    onMounted(() => {
      getUserSettions()
    })
    return {
      settions
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  created() {
    this.getRows()
  },
  mounted() {
    socialvue.index()
  },
  data() {
    return {
      list: [],
      primaryKey: 'created_at',
      commomData: commomData()
    }
  },
  methods: {
    addPost(post) {
      this.socialPosts.unshift(post)
    },
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.post_type = 4
      this.commomData.condition.page_size = 2
      await getPost(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          } else {
            this.commomData.noResult = true
          }
        })
        .catch(err => {
          console.log(err)
          // this.$swal.mixin().fire({
          //   icon: 'error',
          //   title: 'err.response.data.message'
          // })
        })
    }
  }
}
</script>
